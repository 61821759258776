<template>
      <v-dialog
        v-model="dialog"
        :max-width="width"
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :class="btnclass"
            :color="btncolor"
            :disabled="disabled"
            v-bind="attrs"
            v-on="on"
            :outlined="outlined"
            :block="btnblock"
            :tile="btntile"
            :x-large="btnsizeLarge"
          >
            <span :style="'color:'+btncolorText">{{button}}</span>
          </v-btn>
        </template>
          
        <v-card>
          <v-toolbar
            color="primary"
            dark
          >
            <v-btn
              icon
              @click="onClose(false)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title class="text-uppercase" v-html="title"></v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
                 <v-container>
                 <v-row>
                    <v-col>
                         <slot :onClose="onClose" ></slot>
                    </v-col>
                 </v-row>
                 </v-container>
            </v-card-text>
            
        </v-card>
      </v-dialog>
</template>

<script>

export default {
     name: "DialogSimple",
     props:{
       button:{
         type:String,
         default: 'commons.open'
       },
       title:{
         type:String,
         default: 'commons.title'
       },
       width:{
         type:String,
         default: '500px'
       },
       disabled:{
         type: Boolean,
         default: false
       },
       outlined:{
         type: Boolean,
         default: false
       },
       btncolor:{
         type: String,
         default: 'primary'
       },
       btnclass:{
         type: String,
         default: ''
       },
       btnblock:{
         type: Boolean,
         default: false
       },
       btnsizeLarge:{
         type: Boolean,
         default: false
       },
       btntile:{
         type: Boolean,
         default: false
       },
       btncolorText:{
         type: String,
         default: null
       }
     },
     data: () => ({
          dialog: false
     }),
     methods:{
       onClose(val) {
        this.dialog = val;
        console.log('dialog',val);
        console.log("CLOSING DIALOG");
        this.$emit('onClose')
      }
     }
}
</script>